// =====================================
//                COLORS
// =====================================

$oyat-white: #FFFFFF;
$hamburger-layer-color: $oyat-white;
$oyat-bg-primary: #FAF9F7;
$border-table-color: #DFDFDF;
$main-color-light: #F0EBE5;
$oyat-sand-darker: #B8A082;
$main-color: #405862;
$primary: $main-color;
$oyat-black: #0B0B0B;
$facebook: #3B5998;
$google: #DB4A39;

$theme-colors: (
    'main-color': $main-color,
    'oyat-black': $oyat-black,
    'oyat-white': $oyat-white,
    'facebook': $facebook,
    'google': $google,
);

// =====================================
//                FONTS
// =====================================

$weightThin: 100;
$weightExtraLight: 200;
$weightLight: 300;
$weightRegular: 400;
$weightMedium: 500;
$weightSemiBold: 600;
$weightBold: 700;
$weightExtraBold: 800;
$weightBlack: 900;

// =====================================
//                DIVERS
// =====================================

$custom-file-text: (
    fr: "Parcourir"
);

$enable-rounded: false;

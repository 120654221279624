@import "imports";

.login.bg-circle {
    @include media-breakpoint-down(lg) {
        &:before {
            display: none;
        }
    }
}

#login-content {
    .social-login {
        .btn-social {
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: .2s all ease-in-out;

            p {
                color: $oyat-black;
            }

            &:hover {
                text-decoration: none;
                transform: translateY(-5px);

                p {
                    color: $oyat-black;
                }
            }

            &.disabled {
                img {
                    filter: grayscale(1);
                }

                &:hover {
                    transform: none;
                }
            }
        }
    }

    .login-or {
        p {
            text-transform: uppercase;
            margin-bottom: 0;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before, &:after {
                content: "";
                position: absolute;
                width: 40%;
                height: 1px;
                background-color: $oyat-black;
            }

            &:before {
                right: 50%;
                margin-right: 10%;
            }

            &:after {
                left: 50%;
                margin-left: 10%;
            }
        }
    }
}
